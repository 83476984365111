import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { animated, useInView, useSprings } from "@react-spring/web";
import { Metrics } from "modules/landing/components/main/components/key-metrics-section/components/metrics/Metrics";
import MetricsImg from "./assets/metrics.png";
import MetricsMobileImg from "./assets/metricsMobile.png";

const AnimatedStack = animated(Stack);
const AnimatedBox = animated(Box);

const elements = [
  { type: "text", content: "This is a text" },
  { type: "image", content: <img src="example.jpg" alt="Example" /> },
  { type: "card", content: "Card 1" },
  { type: "card", content: "Card 2" },
  { type: "card", content: "Card 3" },
];

export const KeyMetricsSection = () => {
  const [ref, isInView] = useInView({
    once: true,
  });

  const springs = useSprings(
    elements.length,
    elements.map((_, index) => ({
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateY(0)" : `translateY(20px)`,
      from: { opacity: 0, transform: "translateY(20px)" },
      config: { tension: 150, friction: 15 },
      delay: 200 + index * 100, // Индивидуальная задержка для каждого элемента
    }))
  );

  const [text, image, ...cards] = springs;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack direction={{ xs: "column", mediumLarge: "row" }} gap={7}>
      <Stack gap={{ xs: 5, md: 7 }}>
        <AnimatedStack style={text} ref={ref} gap={2} width="100%">
          <Typography variant="h2">Performance Proven With Numbers</Typography>
          <Typography variant="subtitle1" color="text.secondary" maxWidth={600}>
            Explore Prom ever growing key metrics highlighting network dynamics
            and user adoption
          </Typography>
          <Box height={50}></Box>
          {/* 
          TODO: REMOVE BOX ABOVE IF RETURNED
          <Link legacyBehavior href="/bridge" passHref>
            <Button
              variant="contained"
              size="large"
              component="a"
              sx={{
                maxWidth: 200,
              }}
            >
              Bridge to Prom
            </Button>
          </Link> */}
        </AnimatedStack>
        {isMobile ? (
          <Box display="flex" overflow="hidden" borderRadius={4}>
            <img
              style={{ objectFit: "cover", minHeight: 250 }}
              width="100%"
              src={MetricsMobileImg.src}
              alt="Metrics"
            />
          </Box>
        ) : null}
        <Metrics cardStyles={cards} />
      </Stack>
      {!isMobile ? (
        <AnimatedBox
          style={image}
          display="flex"
          overflow="hidden"
          borderRadius={4}
        >
          <img
            style={{ objectFit: "cover" }}
            width="100%"
            src={MetricsImg.src}
            alt="Metrics"
          />
        </AnimatedBox>
      ) : null}
    </Stack>
  );
};
