import { Main } from "modules/landing/components/main";
import { Footer } from "modules/shared/page/footer";
import { Header } from "modules/shared/page/header";
import React from "react";
import { LandingHead } from "./components/landing-head";
import { Stack } from "@mui/material";

export const Landing = () => {
  return (
    <>
      <LandingHead />
      <Stack
        height="100%"
        flexGrow={1}
        flexDirection="column"
        gap={5}
        m={{ xs: 3, lg: 16 }}
        mb={{ xs: 0, lg: 0 }}
        mt={{ xs: 0, lg: 0 }}
      >
        <Header landingMode={true} />
        <Stack gap={25}>
          <Main />
          <Footer />
        </Stack>
      </Stack>
    </>
  );
};
