"use client";

import { Container, Stack } from "@mui/material";
import { DaosSection } from "modules/landing/components/main/components/daos-section";
import { PromEcosystemSection } from "modules/landing/components/main/components/ecosystem-section";
import { HeroSection } from "modules/landing/components/main/components/hero-section/HeroSection";
import { KeyMetricsSection } from "modules/landing/components/main/components/key-metrics-section";
import { AdvancementSection } from "modules/landing/components/main/components/advancement-section";
import { KeystoneSection } from "modules/landing/components/main/components/keystone-section/KeystoneSection";
import { LastSection } from "modules/landing/components/main/components/last-section";
import { PromCardsSection } from "modules/landing/components/main/components/prom-cards-section";

export const Main = () => {
  return (
    <Container maxWidth="xl" sx={{ padding: 0 }} disableGutters>
      <Stack gap={{ xs: 12, md: 25 }} width="100%" height="100%">
        <HeroSection />
        <AdvancementSection />
        <KeyMetricsSection />
        <DaosSection />
        <PromEcosystemSection />
        <KeystoneSection />
        <PromCardsSection />
        <LastSection />
      </Stack>
    </Container>
  );
};
