import { Box, Button, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import EastIcon from "@mui/icons-material/East";
import PreviewImg from "modules/landing/components/main/components/hero-section/assets/preview.png";
import Link from "next/link";

export const Video = () => {
  const theme = useTheme();
  return (
    <Box
      maxWidth="1105px"
      width="100%"
      maxHeight="460px"
      borderRadius={5}
      overflow="hidden"
      position="relative"
    >
      <video
        playsInline={true}
        width="100%"
        height="100%"
        autoPlay
        controls={false}
        loop
        muted
        poster={PreviewImg.src}
        style={{
          objectFit: "cover",
          minHeight: 300,
        }}
      >
        <source src="/static/videos/video.mp4" type="video/mp4" />
      </video>
      <Link
        legacyBehavior
        href="https://prom.gitbook.io/prom/introduction"
        passHref
      >
        <Button
          color="secondary"
          sx={{
            width: 159,
            position: "absolute",
            left: 24,
            bottom: 24,
            background: alpha(theme.palette.background.default, 0.85),
            ":hover": {
              transition: "padding 0.5s ease-in",
              paddingLeft: "30px",
              background: alpha(theme.palette.background.default, 0.85),
            },
          }}
          variant="contained"
          endIcon={<EastIcon />}
        >
          Learn more
        </Button>
      </Link>
    </Box>
  );
};
