import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useRef } from "react";
import CoastIcon from "./assets/coast.png";
import HighIcon from "./assets/high.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const KEYSTONE_CARDS = [
  {
    id: 0,
    img: CoastIcon.src,
    title: "Cost-Efficiency via ZK Proofs",
    text: "Powered by Polygon, PROM uses zkSNARKs to slash transaction costs, offering users an economical blockchain solution.",
  },
  {
    id: 1,
    img: HighIcon.src,
    title: "High-Speed Scalability",
    text: "PROM harnesses Polygon CDK for rapid transactions and scalable dApp development, thanks to cutting-edge recursive STARK technology.",
  },
  {
    id: 2,
    img: CoastIcon.src,
    title: "Effortless Security",
    text: "Leveraging the efficiency of ZK proofs, PROM ensures secure and immutable transactions and unbreakable data integrity.",
  },
];

const ArrowButton = styled(Button)(({ theme }) => ({
  minWidth: "fit-content",
  width: 25,
  height: 25,
  padding: 0,
  borderRadius: "50%",
  overflow: "hidden",
  border: "2px solid black",
  "&:hover": {
    background: theme.palette.grey.A200,
    outline: `4px solid ${theme.palette.grey.A200}`,
  },
  svg: {
    width: 20.5,
    height: 20.5,
    "&:hover": {
      background: theme.palette.grey.A200,
    },
  },
}));

export const AdvancementCards = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const containerRef = useRef<HTMLDivElement>();

  const handleCarouselClick = (signNumber: number) => () =>
    containerRef.current?.scrollBy({
      left: signNumber * (isMobile ? 275 : 336),
      behavior: "smooth",
    });

  return (
    <Stack width="100%" overflow="hidden" height={390} padding={0} paddingX={1}>
      <Box
        ref={containerRef}
        display="flex"
        flexDirection="row"
        overflow={{ xs: "auto", md: "hidden" }}
        width="100%"
        gap={4}
        height={{ xs: 360 }}
        padding={2.5}
        sx={{
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#e0e0e0", // Цвет заднего фона скроллбара
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#2c2c2c", // Цвет ползунка скроллбара
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555", // Цвет ползунка скроллбара при наведении
          },
        }}
      >
        {KEYSTONE_CARDS.map(({ img, text, title, id }) => {
          return (
            <Box
              key={id}
              display="flex"
              flexDirection="column"
              bgcolor="background.default"
              boxShadow="2px 2px 10px 0px #1212120F"
              flexShrink={0}
              width={{
                xs: 256,
                lg: 320,
                xl: 365,
              }}
              height={320}
              padding={3}
              borderRadius={3}
              gap={4}
            >
              <img height={120} src={img} alt={title} />
              <Stack display="flex" direction="column" gap={3}>
                <Typography variant="subtitle1">{title}</Typography>
                <Typography variant="body1" color="text.secondary">
                  {text}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Box>
      <Stack
        display={{ xs: "none", md: "flex" }}
        direction="row"
        gap={5}
        marginLeft="auto"
        marginRight={7}
      >
        <ArrowButton onClick={handleCarouselClick(-1)}>
          <ArrowBackIcon />
        </ArrowButton>
        <ArrowButton onClick={handleCarouselClick(1)}>
          <ArrowForwardIcon />
        </ArrowButton>
      </Stack>
    </Stack>
  );
};
