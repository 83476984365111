import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { animated, useInView, useSpring } from "@react-spring/web";
import { DelegateCards } from "modules/landing/components/main/components/daos-section/components/deligate-cards";
import Link from "next/link";
import BackgroundImg from "./assets/background.png";
import CubMobileImg from "./assets/cubMobile.png";

const AnimatedBackground = animated(Box);

export const DaosSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [ref, isInView] = useInView({
    once: true,
  });

  const animationStyle = useSpring({
    transform: isInView ? "scale(1.5)" : "scale(1)",
    config: {
      tension: 20,
      friction: 4,
    },
  });

  return (
    <Box
      ref={ref}
      position="relative"
      borderRadius={8}
      overflow="hidden"
      bgcolor="grey.300"
    >
      <img
        width="100%"
        height="100%"
        alt="background"
        src={BackgroundImg.src}
        style={{
          position: "absolute",
          objectFit: "cover",
        }}
      />
      {!isMobile ? (
        <AnimatedBackground
          maxHeight={257}
          style={{
            position: "absolute",
            objectFit: "contain",
            width: 500,
            right: "10%",
            top: 77,
            ...animationStyle,
          }}
        >
          <img width="100%" height="100%" alt="cub" src={CubMobileImg.src} />
        </AnimatedBackground>
      ) : null}
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        gap={{ xs: 6, md: 12 }}
        padding={{ xs: 4, md: 9 }}
        zIndex={1}
      >
        <Stack gap={5} maxWidth={466} width="100%" alignItems="baseline">
          <Typography variant="h2">
            A Community-Centric Approach to Governance
          </Typography>
          <Typography variant="body1">
            We believe that every great product is shaped by a great community.
            Prom development is driven by on-chain decision-making process and
            transparent governance, unlocking limitless potential for community
            members to directly contribute to further product growth.
          </Typography>
          <Link
            legacyBehavior
            href="https://prom.gitbook.io/prom/introduction"
            passHref
          >
            <Button variant="contained" size="medium">
              Learn more
            </Button>
          </Link>
        </Stack>
        {isMobile ? (
          <Box maxHeight={257}>
            <img width="100%" height="100%" alt="cub" src={CubMobileImg.src} />
          </Box>
        ) : null}
        <DelegateCards />
      </Box>
    </Box>
  );
};
