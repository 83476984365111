import { Box, Button, ButtonProps, Stack, Typography } from "@mui/material";
import { animated, useInView, useSprings } from "@react-spring/web";
import Link from "next/link";
import BuildImg from "./assets/build.png";
import ValidatorsImg from "./assets/validators.png";

interface CardButton {
  name: string;
  variant: ButtonProps["variant"];
  href: string;
}

interface Card {
  title: string;
  description: string;
  img: string;
  buttons: CardButton[];
}

const CARDS: Card[] = [
  {
    title: "Build on Prom",
    description:
      "Leverage Prom toolset for building. Benefit from extensive support, incentives, and grants and unlock opportunities within our ecosystem",
    img: BuildImg.src,
    buttons: [
      {
        name: "Start Building",
        variant: "contained",
        href: "https://prom.gitbook.io/prom/start-building/get-started",
      },
      {
        name: "Apply for a Grant",
        variant: "outlined",
        href: "/grant",
      },
    ],
  },
  {
    title: "Become a Network Validator",
    description:
      "Play a vital role in the life of the Prom network by contributing as a Validator. Maintain the network’s health, integrity, and security",
    img: ValidatorsImg.src,
    buttons: [
      {
        name: "Become a validator",
        variant: "contained",
        href: "https://forge.prom.io/",
      },
    ],
  },
];

const AnimatedPromoCard = animated(Box);

export const PromCardsSection = () => {
  const [ref, isInView] = useInView({
    once: true,
  });

  const springs = useSprings(
    CARDS.length,
    CARDS.map((_, index) => ({
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateY(0)" : "translateY(20px)",
      from: { opacity: 0, transform: "translateY(20px)" },
      config: { tension: 150, friction: 15 },
      delay: 200 + index * 100,
    }))
  );

  return (
    <Stack ref={ref} gap={8} flexDirection={{ xs: "column", sm: "row" }}>
      {CARDS.map(({ buttons, title, description, img }, index) => {
        const style = springs[index];

        return (
          <AnimatedPromoCard
            key={title}
            display="flex"
            flexDirection={{ xs: "column", mediumLarge: "row" }}
            padding={5}
            borderRadius={5}
            gap={{ xs: 5, md: 10 }}
            border="1px solid"
            borderColor="grey.200"
            style={style}
          >
            <Stack
              width="100%"
              maxWidth={{
                xl: 400,
                lg: 160,
              }}
              borderRadius={3}
              overflow="hidden"
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                width="100%"
                height="100%"
                src={img}
                alt={title}
              />
            </Stack>
            <Stack gap={4}>
              <Stack gap={2}>
                <Typography variant="h2">{title}</Typography>
                <Typography variant="body1" color="text.secondary">
                  {description}
                </Typography>
              </Stack>
              <Stack
                display="flex"
                flexDirection={{ xs: "column", mediumLarge: "row" }}
                gap={{ xs: 3, md: 7 }}
              >
                {buttons.map(({ name, variant, href = "" }) => (
                  <Link key={name} legacyBehavior href={href} passHref>
                    <Button
                      size="small"
                      disabled={name === "Become a validator" && true}
                      variant={variant}
                      component="a"
                      sx={{
                        maxWidth: { mediumLarge: "180px" },
                        minWidth: "fit-content",
                        width: "100%",
                        height: "32px",
                        padding: "7.5px",
                      }}
                    >
                      {name}
                    </Button>
                  </Link>
                ))}
              </Stack>
            </Stack>
          </AnimatedPromoCard>
        );
      })}
    </Stack>
  );
};
