import { ChainId } from "config/wagmi";
import { avalanche, bsc, Chain, mainnet, polygon } from "wagmi/chains";

type NetworkContractsConfig = {
  chainId: ChainId;
  contracts: {
    bridge: string;
  };
};

const polygonConfig: NetworkContractsConfig = {
  chainId: polygon.id,
  contracts: {
    bridge: "",
  },
};

const bscConfig: NetworkContractsConfig = {
  chainId: bsc.id,
  contracts: {
    bridge: "0x8bdd3e77aD7843838cB0F0c19842764e353C2076",
  },
};

const avaxConfig: NetworkContractsConfig = {
  chainId: avalanche.id,
  contracts: {
    bridge: "",
  },
};

export const MAINNET_BRIDGE = "0x19Cc29954A602761aa3180cd0A33752DcBF4c290";

const ethereumConfig: NetworkContractsConfig = {
  chainId: mainnet.id,
  contracts: {
    bridge: MAINNET_BRIDGE,
  },
};

export const PROM_TESTNET_CHAIN_ID = 584548796; // TODO: Move that to networks.ts file
export const PROM_MAINNET_CHAIN_ID = 227;

export const promTestnet: Chain = {
  testnet: true,
  id: PROM_TESTNET_CHAIN_ID,
  name: "Prom Testnet",
  nativeCurrency: {
    name: "Prom",
    symbol: "PROM",
    decimals: 18,
  },
  blockExplorers: {
    default: {
      name: "Prom Testnet Explorer",
      url: "prom-testnet-blockscout.eu-north-2.gateway.fm",
    },
  },
  rpcUrls: {
    default: {
      http: [
        "https://testnet-rpc.prom.io/?apiKey=Tx0Dk5kq_ZpX2FJH3cim2hDF1CaBAV57.FIC2IrXcjVrNLi7U",
      ],
    },
  },
  contracts: {
    multicall3: {
      address: "0x4312B2F4834f1b1c47B8114aC31a599747a5d445",
      blockCreated: 55613,
    },
  },
};

export const promMainnet: Chain = {
  id: 227,
  name: "Prom Mainnet",
  nativeCurrency: {
    name: "Prom",
    symbol: "PROM",
    decimals: 18,
  },
  blockExplorers: {
    default: {
      name: "Prom Mainnet Explorer",
      url: "https://promscan.io",
    },
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.prom.io"],
    },
  },
  contracts: {
    multicall3: {
      address: "0xC5353D050c102f5E334FdB71804A93c83c15fB6D",
      blockCreated: 326670,
    },
  },
};

export const promMainnetConfig: NetworkContractsConfig = {
  chainId: PROM_MAINNET_CHAIN_ID,
  contracts: {
    bridge: MAINNET_BRIDGE,
  },
};

export const configs = {
  // [PROM_TESTNET_CHAIN_ID]: promTestnetConfig,
  [promMainnet.id]: promMainnetConfig,
  [mainnet.id]: ethereumConfig,
  // [sepolia.id]: sepoliaConfig,
  [polygon.id]: polygonConfig,
  [bsc.id]: bscConfig,
  [avalanche.id]: avaxConfig,
};
