import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { switchChain } from "@wagmi/core";
import { wagmiConfig } from "config/wagmi";
import { LandingNavigation } from "modules/landing/components/main/components/hero-section/components/landing-navigation/LandingNavigation";
import { Video } from "modules/landing/components/main/components/hero-section/components/video/Video";
import Link from "next/link";
import { useAccount } from "wagmi";
import { AccountButton } from "widgets/account-button";

export const HeroSection = () => {
  const { address } = useAccount();
  const connect = useConnectModal();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAddMetamask = () => {
    if (!address) {
      connect?.openConnectModal?.();
    }
    switchChain(wagmiConfig, {
      chainId: 227,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgcolor="secondary.main"
      gap={{ xs: 10, md: 16 }}
      padding={{ xs: 4, md: 8 }}
      borderRadius={8}
    >
      <Stack gap={{ xs: 5, md: 16 }} direction={{ xs: "column", md: "row" }}>
        <Stack
          gap={{ xs: 5, md: 16 }}
          direction="column"
          justifyContent="center"
          maxWidth={{ xs: "100%", md: "560px" }}
          width="100%"
        >
          <Stack gap={{ xs: 5, md: 7 }} width="100%">
            <Typography variant="h1" sx={{ wordBreak: "break-word" }}>
              Scalability Without Borders
            </Typography>
            <Typography color="text.secondary" variant="h3" textAlign="left">
              High-performance scalable network based on Polygon CDK
            </Typography>
          </Stack>
          {isMobile ? <Video /> : null}
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            gap={6}
            width="100%"
          >
            <Link legacyBehavior href="/bridge" passHref>
              <Button variant="contained" fullWidth size="large" component="a">
                Bridge to Prom
              </Button>
            </Link>

            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={handleAddMetamask}
            >
              Add Network to Wallet
            </Button>
          </Stack>
        </Stack>
        {!isMobile ? <Video /> : null}
      </Stack>
      <LandingNavigation />
    </Box>
  );
};
