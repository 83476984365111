import React from "react";
import Head from "next/head";
const ogImage = "/static/images/Meta1.jpg";
const title = "PROM | Bridging EVM & Non-EVM worlds";

const prefix = "";

export const LandingHead = () => {
  return (
    <Head>
      <link rel="icon" href="/favicon.png" />
      <meta
        property="og:site_name"
        content="Prom"
        data-shuvi-head="true"
        key="og:site_name"
      />
      <meta
        property="twitter:site"
        content="Prom"
        data-shuvi-head="true"
        key="twitter:site"
      />
      <meta
        name="twitter:description"
        content="Modular zkEVM Layer 2 that enables interoperability across various chains. Prom submits its proof of transaction to more chains on top of the chosen Settlement chain, building the bridge between ecosystems."
      />

      <React.Fragment>
        <title>
          {prefix}
          {title}
        </title>
        <meta
          property="og:title"
          content={`${prefix}${title}`}
          key="og_title"
        />
        <meta
          name="twitter:title"
          content={`${prefix}${title}`}
          key="twitter_title"
        />
      </React.Fragment>

      <meta
        property="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta property="og:image" content={ogImage} key="og:image" />
      <meta name="twitter:image" content={ogImage} key="twitter:image" />
    </Head>
  );
};
