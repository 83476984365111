import { Stack, type StackProps, Tooltip, Typography } from "@mui/material";
import NextLink from "next/link";
import type { FC } from "react";

type MenuLink = {
  title: string;
  items: { href: string; label: string; comingSoon?: boolean }[];
};

const bottomMenuLinks: MenuLink[] = [
  {
    title: "About us",
    items: [
      {
        href: "https://jobs.polymer.co/prom",
        label: "Careers",
      },
      {
        href: "https://prom-io.medium.com/",
        label: "Blog",
      },
      {
        label: "Become a partner",
        href: "/partnership",
      },
      {
        label: "Bridge PROM",
        href: "/bridge",
      },
    ],
  },
  {
    title: "Legal",
    items: [
      {
        href: "/privacy-policy",
        label: "Privacy Policy",
      },
      {
        href: "/terms-of-use",
        label: "Terms of Use",
      },
    ],
  },
];

export const BottomMenu: FC<StackProps> = (props) => {
  return (
    <Stack
      {...props}
      direction={{ xs: "column", md: "row" }}
      justifyContent={{ xs: "start", md: "center" }}
      columnGap={25}
      flexWrap="wrap"
      rowGap={5}
      sx={{
        marginRight: 2,
      }}
    >
      {bottomMenuLinks.map((links, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Stack direction="column" gap={4} key={index}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              opacity: links.title ? 1 : 0,
            }}
            aria-hidden={!links.title}
          >
            {links.title || "invisible"}
          </Typography>

          <Stack direction="column" gap={4}>
            {links.items.map((link) =>
              link.comingSoon ? (
                <Tooltip
                  placement="top"
                  title="Coming soon"
                  key={link.href}
                  arrow
                >
                  <Typography variant="body1" color="text.primary">
                    {link.label}
                  </Typography>
                </Tooltip>
              ) : (
                <NextLink
                  href={link.href}
                  key={link.href}
                  passHref
                  target="_blank"
                  rel="noopener noreferrer"
                  legacyBehavior
                >
                  <Typography component="a" variant="body1" key={link.href}>
                    {link.label}
                  </Typography>
                </NextLink>
              )
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
