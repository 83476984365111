"use client";

import { Box, Stack, Typography } from "@mui/material";
import { animated, SpringValue } from "@react-spring/web";

const METRICS = [
  {
    title: "2.5M+",
    description: "Unique wallets",
  },
  {
    title: "26M+",
    description: "Transactions on chains",
  },
  {
    title: "<6s",
    description: "Average block time",
  },
];

const AnimatedBox = animated(Box);

interface AnimationCardStyle {
  opacity: SpringValue<number>;
  transform: SpringValue<string>;
}

interface Props {
  cardStyles: AnimationCardStyle[];
}
export const Metrics = ({ cardStyles }: Props) => {
  return (
    <Stack
      direction={{ xs: "column", mediumLarge: "row" }}
      gap={2}
      width="100%"
    >
      {METRICS.map(({ title, description }, index) => {
        const style = cardStyles[index];

        return (
          <AnimatedBox
            key={title}
            display="flex"
            flexDirection="column"
            justifyContent={{ xs: "center", md: "end" }}
            alignItems={{ xs: "center", md: "baseline" }}
            gap={1}
            minWidth={240}
            width="100%"
            height={120}
            padding={3}
            borderRadius={3}
            bgcolor="secondary.main"
            style={style}
          >
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
          </AnimatedBox>
        );
      })}
    </Stack>
  );
};
