import { Box, Button, Stack, Typography } from "@mui/material";
import { Feed } from "modules/landing/components/main/components/ecosystem-section/Feed";
import { useEcosystemPartnersQuery } from "modules/shared/graphql/stitched";
import Link from "next/link";

export const PromEcosystemSection = () => {
  const { data } = useEcosystemPartnersQuery({
    variables: {
      pagination: {
        limit: 1000,
      },
      sort: ["priority:desc"],
    },
  });
  const ecosystemPartners = data?.ecosystemPartners?.data || [];

  const partners = ecosystemPartners.map(({ attributes }) => {
    return {
      logo:
        attributes?.landing_logo?.data?.[0]?.attributes?.url ||
        attributes?.logo?.data?.[0]?.attributes?.url,
      url: attributes?.url || "",
    };
  });

  const middleIndex = Math.floor(partners.length / 2);

  const firstHalf = partners.slice(0, middleIndex);
  const secondHalf = partners.slice(middleIndex);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={10}
      borderRadius={8}
      gap={12}
      overflow="hidden"
      width="100%"
    >
      <Typography variant="h2">Prom ecosystem</Typography>

      <Stack gap={5} alignItems="center">
        <Feed>
          <Stack gap={5}>
            <Stack flexDirection="row" gap={5}>
              {firstHalf.map(({ logo, url }) => {
                return (
                  <Stack
                    key={url}
                    borderRadius="12px"
                    padding={3}
                    minWidth="220px"
                    height="60px"
                    sx={{ background: "#ECECEC" }}
                  >
                    <img
                      style={{ objectFit: "contain" }}
                      width="100%"
                      height="100%"
                      alt="partner"
                      src={logo}
                    />
                  </Stack>
                );
              })}
            </Stack>
            <Stack flexDirection="row" gap={5}>
              {secondHalf.map(({ url, logo }) => {
                return (
                  <Stack
                    key={url}
                    borderRadius="12px"
                    padding={3}
                    width="172px"
                    height="60px"
                    sx={{ background: "#ECECEC" }}
                  >
                    <img
                      style={{ objectFit: "contain" }}
                      width="100%"
                      height="100%"
                      alt="partner"
                      src={logo}
                    />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Feed>
      </Stack>
      <Link legacyBehavior href="/partnership" passHref>
        <Button
          sx={{
            padding: "3.5px 41px",
          }}
          variant="outlined"
          size="small"
        >
          Explore more
        </Button>
      </Link>
    </Box>
  );
};
