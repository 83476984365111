"use client";

import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  frameWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
  zerionWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { promMainnet, promTestnet } from "config/default";
import { createConfig, createStorage, http } from "wagmi";
import { bsc, mainnet, arbitrum, optimism, polygon } from "wagmi/chains";

declare module "wagmi" {
  interface Register {
    config: typeof wagmiConfig;
  }
}

const connectors = connectorsForWallets(
  [
    {
      groupName: "Popular",
      wallets: [
        metaMaskWallet,
        injectedWallet,
        rainbowWallet,
        zerionWallet,
        trustWallet,
        frameWallet,
        okxWallet,
        walletConnectWallet,
      ],
    },
  ],
  {
    appName: "Prom",
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_ID as string,
  }
);

export const wagmiConfig = createConfig({
  connectors,
  chains: [promMainnet, mainnet, bsc, arbitrum, optimism, polygon],
  transports: {
    [promMainnet.id]: http("https://rpc.prom.io"),
    [mainnet.id]: http("https://ethereum-rpc.publicnode.com"),
    [bsc.id]: http(
      "https://multi-flashy-waterfall.bsc.quiknode.pro/7826bfb6dbe2bae4e2514ec174999ce4c6c0cfb3"
    ),
    [optimism.id]: http("https://optimism.llamarpc.com"),
    [arbitrum.id]: http("https://arbitrum.llamarpc.com"),
    [polygon.id]: http("https://polygon.llamarpc.com"),
  },
  storage: createStorage({
    storage: typeof localStorage === "undefined" ? undefined : localStorage,
    key: "prom-1.0.2",
  }),
  ssr: true,
});

export type ChainId = (typeof wagmiConfig)["chains"][number]["id"];
