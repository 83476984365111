import { Box, Button, Stack, Typography } from "@mui/material";
import { animated, useInView, useSpring } from "@react-spring/web";
import Link from "next/link";
import GradientSvg from "./assets/gradient.svg";

const AnimatedBlock = animated(Box);

export const LastSection = () => {
  const [ref, isInView] = useInView({
    once: true,
  });

  const animationStyle = useSpring({
    opacity: isInView ? 1 : 0,
    transform: isInView ? "translateY(0)" : "translateY(20px)",
    from: { opacity: 0, transform: "translateY(20px)" },
    config: { tension: 150, friction: 15 },
    delay: 200,
  });

  return (
    <AnimatedBlock
      ref={ref}
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding={{ xs: 7, md: 18 }}
      gap={10}
      borderRadius={8}
      bgcolor="grey.300"
      overflow="hidden"
      style={animationStyle}
    >
      <Stack position="absolute" top={0}>
        <GradientSvg />
      </Stack>
      <Stack gap={3} zIndex={1} textAlign="center">
        <Typography variant="h1">
          The Future is Multichain: Shape it with Us
        </Typography>
        <Typography variant="body1" color="text.secondary">
          If you share our vision and want to explore ways to collaborate, get
          in touch with our team for further details
        </Typography>
      </Stack>
      <Link legacyBehavior href="/contacts" passHref>
        <Button
          variant="contained"
          size="large"
          sx={{
            maxWidth: "200px",
            width: "100%",
          }}
        >
          Contact us
        </Button>
      </Link>
    </AnimatedBlock>
  );
};
