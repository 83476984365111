"use client";

import { Box, Button, Stack, Typography } from "@mui/material";
import PromExplorerIcon from "./assets/promExplorer.png";
import PromEcosystemIcon from "./assets/promEcosystem.png";
import PromBuildIcon from "./assets/promBuild.png";
import Link from "next/link";
import ArrowIcon from "./assets/Arrow.svg";
import { useInView, animated, useSprings } from "@react-spring/web";

const LANDING_NAVIGATION_BUTTONS = [
  {
    label: "Prom Explorer",
    href: "https://promscan.io",
    icon: PromExplorerIcon.src,
  },
  {
    label: "Prom Ecosystem",
    href: "/partnership",
    icon: PromEcosystemIcon.src,
  },
  {
    label: "Build on Prom",
    href: "https://prom.gitbook.io/prom/start-building/get-started",
    icon: PromBuildIcon.src,
  },
];

const AnimatedButton = animated(Stack);

export const LandingNavigation = () => {
  const [ref, isInView] = useInView({
    once: true,
  });

  const trail = useSprings(
    LANDING_NAVIGATION_BUTTONS.length,
    LANDING_NAVIGATION_BUTTONS.map((_, index) => ({
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateY(0)" : `translateY(${20 + index * 5}px)`,
      from: { opacity: 0, transform: `translateY(${20 + index * 5}px)` },
      config: { tension: 150, friction: 15 },
      delay: 200 + index * 100,
    }))
  );

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      gap={{ xs: 5, md: 10 }}
      alignItems="center"
      ref={ref}
    >
      {LANDING_NAVIGATION_BUTTONS.map(({ label, href, icon }, index) => {
        const style = trail[index];
        return (
          <AnimatedButton key={href} style={style}>
            <Link legacyBehavior href={href} passHref>
              <Button
                variant="outlined"
                component="a"
                sx={{
                  padding: 0,
                  borderRadius: 5,
                  borderColor: "transparent",
                  overflow: "hidden",
                  maxWidth: "550px",
                  width: "100%",
                  ":hover": { borderColor: "transparent" },
                }}
              >
                <Box bgcolor="grey.300" flexDirection="column">
                  <img width="100%" height="100%" src={icon} alt={label} />
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    padding={6}
                    paddingTop={0}
                  >
                    <Typography
                      fontSize={{ xs: "1.10rem", md: "1.25rem" }}
                      fontWeight={600}
                    >
                      {label}
                    </Typography>
                    <ArrowIcon />
                  </Stack>
                </Box>
              </Button>
            </Link>
          </AnimatedButton>
        );
      })}
    </Stack>
  );
};
