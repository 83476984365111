import { Stack, StackProps } from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";

interface Props extends StackProps {
  children: ReactNode;
  // initialPosition: number;
}

export const Feed = ({ children }: Props) => {
  const [direction, setDirection] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const positionRef = useRef(0);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const animate = () => {
      positionRef.current += direction;

      const container = containerRef.current;

      const containerRect = container!.getBoundingClientRect();
      if (direction === -1 && containerRect.right - window.innerWidth <= 100) {
        setDirection(1);
      }

      if (direction === 1 && containerRect.x >= 10) {
        setDirection(-1);
      }

      container!.style.transform = `translateX(${positionRef.current}px)`;

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [direction]);

  return (
    <Stack width="100%" overflow="hidden">
      <Stack
        ref={containerRef}
        display="flex"
        width="100%"
        gap={6}
        flexDirection="row"
      >
        {children}
      </Stack>
    </Stack>
  );
};
