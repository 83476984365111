import { Button, Stack, Typography } from "@mui/material";
import { animated, useInView, useSprings, useTrail } from "@react-spring/web";
import { KeyStoneCards } from "modules/landing/components/main/components/keystone-section/components/keyston-cards";
import Link from "next/link";

const AnimatedText = animated(Stack);

const elements = [
  { type: "text", content: "This is a text" },
  { type: "card", content: "Card 1" },
  { type: "card", content: "Card 2" },
  { type: "card", content: "Card 3" },
];

export const KeystoneSection = () => {
  const [ref, isInView] = useInView({ once: true });

  const springs = useSprings(
    elements.length,
    elements.map((_, index) => ({
      opacity: isInView ? 1 : 0,
      transform: isInView ? "translateY(0)" : `translateY(20px)`,
      from: { opacity: 0, transform: "translateY(20px)" },
      config: { tension: 150, friction: 15 },
      delay: 200 + index * 100, // Увеличение задержки для каждой анимации
    }))
  );

  const [animationTextStyle, ...animationCards] = springs;

  return (
    <Stack
      ref={ref}
      flexDirection={{ mediumLarge: "row" }}
      justifyContent="space-between"
      alignItems="flex-start"
      gap={{ xs: 6, md: 24 }}
    >
      <AnimatedText
        width="100%"
        gap={{ xs: 3, md: 7 }}
        maxWidth={500}
        style={animationTextStyle}
      >
        <Typography variant="h2">
          $PROM: The Keystone of Prom Network
        </Typography>
        <Typography variant="body1">
          $PROM is the native token that powers the Prom ecosystem, facilitating
          every transaction, empowering users with governance, and incentivizing
          community contributions.
        </Typography>
        {/* <Link legacyBehavior href="/bridge" passHref>
          <Button
            variant="contained"
            size="large"
            component="a"
            sx={{ maxWidth: 200 }}
          >
            Bridge to Prom
          </Button>
        </Link> */}
      </AnimatedText>
      <KeyStoneCards cardAnimationStyles={animationCards} />
    </Stack>
  );
};
