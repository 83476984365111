import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdvancementCards } from "modules/landing/components/main/components/advancement-section/components/advancement-cards/AdvancementCards";
import Link from "next/link";

export const AdvancementSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      padding={{ xs: 4, mediumLarge: 15 }}
      alignItems={{ xs: "center", md: "flex-start" }}
      paddingRight={{ xs: 0, mediumLarge: 0 }}
      borderRadius={8}
      bgcolor="grey.300"
      gap={{ xs: 6, md: 15 }}
    >
      <Stack
        display="flex"
        direction="column"
        flexShrink={0}
        gap={4}
        maxWidth={{ xs: "100%", md: "484px" }}
        width="100%"
      >
        <Typography variant="h2" fontSize="36px">
          <Stack>Unveiling the Next</Stack>
          <Stack>Frontier: Breakthroughs</Stack>
          <Stack> in Blockchain</Stack>
          <Stack>Advancement</Stack>
        </Typography>
        {isMobile ? <AdvancementCards /> : null}
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          paddingRight={{ xs: 4 }}
          gap={{ xs: 3, md: 6 }}
          width="100%"
        >
          <Link
            legacyBehavior
            href="https://prom.gitbook.io/prom/start-building/get-started"
            passHref
          >
            <Button variant="contained" fullWidth size="large">
              Start Building
            </Button>
          </Link>
          <Link legacyBehavior href="/grant" passHref>
            <Button variant="outlined" fullWidth size="large">
              Apply for a Grant
            </Button>
          </Link>
        </Stack>
      </Stack>
      {!isMobile ? <AdvancementCards /> : null}
    </Box>
  );
};
