import {
  Box,
  BoxProps,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SocialNetworks } from "modules/shared/components/social-networks/SocialNetworks";
import { FC } from "react";
import { Logo } from "../../components/logo";
import AuditTick from "./AuditTick.svg";
import { BottomMenu } from "./bottom-menu/BottomMenu";

export const Footer: FC<BoxProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        {...props}
        sx={{
          ...props.sx,
          pb: 12,
          backgroundColor: "navigation.main",
        }}
      >
        <Container maxWidth="lg" sx={{ px: [4, 4, 0] }}>
          {!isMobile && <Divider sx={{ mb: 13 }} />}

          {isMobile ? (
            <Stack gap={8}>
              <Logo />
              <BottomMenu />
              <SocialNetworks
                sx={{ marginTop: -1 }}
                gridTemplateColumns="repeat(4, max-content)"
                rowGap={5}
                columnGap={15}
                width="100%"
              />
              © 2024 Prom
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              rowGap={20}
            >
              <Stack gap={2} alignItems="start">
                <Stack direction="row" gap={[6, 6, 27]} alignItems="center">
                  <Logo />
                  <SocialNetworks
                    columnGap={6}
                    width="100%"
                    gridTemplateColumns="repeat(8, max-content)"
                    alignItems="center"
                    sx={{ marginTop: -1 }}
                  />
                </Stack>
                {/* <Stack direction="row" pt={18} spacing={2}>
                  <AuditTick />
                  <Link
                    href="https://support.prom.io/hc/en-gb/articles/7745628233629-Prom-audits"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography>
                      Audited by Blocksec, Hacken, & Slowmist
                    </Typography>
                  </Link>
                </Stack> */}
                <Typography pt={18}>© 2024 Prom</Typography>
              </Stack>
              <BottomMenu />
            </Stack>
          )}
        </Container>
      </Box>
    </>
  );
};
