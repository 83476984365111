import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { animated, SpringValue } from "@react-spring/web";
import GovernanceImg from "modules/landing/components/main/components/keystone-section/assets/Governance.png";
import MobileGovernanceImg from "modules/landing/components/main/components/keystone-section/assets/MobileGovernance.png";
import MobileNetworkAccessImg from "modules/landing/components/main/components/keystone-section/assets/MobileNetworkAccess.png";
import MobileTxImg from "modules/landing/components/main/components/keystone-section/assets/MobileTx.png";
import NetworkAccessImg from "modules/landing/components/main/components/keystone-section/assets/NetworkAccess.png";
import TxPowerImg from "modules/landing/components/main/components/keystone-section/assets/TxPower.png";

const FEATURES = [
  {
    title: "Governance",
    body: "Your $PROM grants you decision-making power, shaping the future of Prom.",
    image: GovernanceImg.src,
    mobileImage: MobileGovernanceImg.src,
  },
  {
    title: "Transaction Power",
    body: "$PROM ensures fast, smooth transactions and contract interactions.",
    image: TxPowerImg.src,
    mobileImage: MobileTxImg.src,
  },
  {
    title: "Network Access",
    body: "Use $PROM to access and engage with a diverse range of network services.",
    image: NetworkAccessImg.src,
    mobileImage: MobileNetworkAccessImg.src,
  },
];

interface AnimationCardStyle {
  opacity: SpringValue<number>;
  transform: SpringValue<string>;
}

interface Props {
  cardAnimationStyles: AnimationCardStyle[];
}

const AnimatedCard = animated(Stack);

export const KeyStoneCards = ({ cardAnimationStyles }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      width="100%"
      gap={3}
      flexDirection={{ xs: "row" }}
      overflow={{ xs: "auto", md: "inherit" }}
      sx={{
        scrollbarColor: `${theme.palette.background.dark} ${theme.palette.grey["300"]}`,
        scrollbarWidth: "thin",
        paddingBottom: 10,
      }}
    >
      {FEATURES.map(({ title, body, mobileImage, image }, index) => {
        const style = cardAnimationStyles[index];

        return (
          <AnimatedCard
            key={title}
            position="relative"
            borderRadius="16px"
            height="300px"
            justifyContent="end"
            alignItems="center"
            width="100%"
            maxWidth={390}
            minWidth={258}
            style={style}
          >
            <Box
              padding={2}
              zIndex={2}
              height="137px"
              marginX={{ xs: 4, md: 2 }}
              bgcolor="rgba(249, 249, 249, 0.85)"
              borderRadius="8px"
              marginBottom="8px"
            >
              <Stack spacing={2} padding={2}>
                <Typography variant="subtitle2">{title}</Typography>

                <Typography variant="body1">{body}</Typography>
              </Stack>
            </Box>

            <Box
              position="absolute"
              width="100%"
              height="100%"
              overflow="hidden"
              borderRadius="16px"
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                width="100%"
                height="100%"
                src={isMobile ? mobileImage : image}
                alt={title}
              />
            </Box>
          </AnimatedCard>
        );
      })}
    </Stack>
  );
};
