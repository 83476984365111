import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Link from "next/link";

const DELEGATE_CARDS = [
  {
    button: "Delegate Prom",
    title: "Become a Prom DAO Delegate",
    description:
      "Act on the behalf of other $PROM holders by collecting voting power through delegation and get rewarded with a share of the total quorum",
  },
  {
    button: "Governance Portal",
    title: "Become a Prom DAO Validator",
    description:
      "Maintain the right curve of Prom development by joining DAO Validators, a second layer Security Council in the governance structure",
  },
];

export const DelegateCards = () => {
  const theme = useTheme();

  return (
    <Stack
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      gap={{ xs: 3, md: 8 }}
    >
      {DELEGATE_CARDS.map(({ title, description, button }) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="baseline"
          justifyContent="space-between"
          key={title}
          borderRadius={4}
          padding={4}
          gap={4}
          width={{ xs: "100%", md: "50%" }}
          bgcolor={alpha(theme.palette.background.default, 0.85)}
        >
          <Stack gap={2}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
          </Stack>
          <Link legacyBehavior href="https://forge.prom.io/" passHref>
            <Button
              sx={{
                padding: "3.5px 41px",
              }}
              variant="outlined"
              size="small"
              disabled
            >
              {button}
            </Button>
          </Link>
        </Box>
      ))}
    </Stack>
  );
};
